import * as actionTypes from '../actions/actionTypes'

const reducer = (state = { postLoginRedirectRoute: null }, action) => {
    switch (action.type) {
        case actionTypes.postLoginRedirectAction:
            return { 
                postLoginRedirectRoute: action.payload 
            }
        default: return state
    }
}

export default reducer
