import { Box, Typography } from "@mui/material";
import React from "react";
import ManOnCube from "../../../public/static/images/man-on-cube.webp";
import ActionButton from "../common/button/actionButton";
import Image from "../image/image";
import { withRouter, Router } from 'next/router'

class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
          error: null,
          errorInfo: null,
          title: null,
          subtitle: null
        };
    }

    componentDidMount(): void {
      Router.events.on('routeChangeComplete', (url) => {
        // reset state on route change
        this.setState({
            error: null,
            errorInfo: null,
            title: null,
            subtitle: null
        })  
      });
    }
    
    componentDidCatch(error, errorInfo) {
        var title = "Something went wrong"
        var subtitle = "Please try again"

        if (error.message == NoAccessError.message) {
            title =  "Hmmm sneaky, sneaky"
            subtitle = "Sorry it looks like you don’t have access to that area."
        }

        this.setState({
            error: error,
            errorInfo: errorInfo,
            title: title,
            subtitle: subtitle
        })

        // NOTE: seems it is not safe to track page view here, need to find another place to track it
        // trackInteraction(InteractionType.pageView, Page.error)
    }

    
    render() {
      if (this.state.error) {
        // Error path
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', height: '88vh', alignItems: 'center'}}>
              <Box sx={{ textAlign: 'center'}}>
                <Image src={ManOnCube} alt={"man on cube"} width={268} height={268}/>
                <Typography sx={{ marginTop: '18px', marginBottom: '8px'}} variant="h4">{this.state.title}</Typography>
                <Typography variant="title2">{this.state.subtitle}</Typography>
                <Box sx={{ marginTop: '44px'}}><ActionButton onClick={() => {this.props.router.push('/home')}} label={"Head back to safety"} variant={"text"} /></Box>
            </Box>
          </Box>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

export default withRouter(ErrorBoundary)

export const NoAccessError = Error("No Access Error")
export const GenericError = Error("Generic Error")
