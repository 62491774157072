import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { logout } from "../../modules";
import { useAuth0 } from '@auth0/auth0-react'; 
import { getCustomerId, isLoggedIn, refreshTokens, savePostLoginRoute } from '../../modules/auth'
import { logger } from "../../modules/logger";
import { extractActualRoutePath } from "../../util/utils";

export function AuthGuard({ children }: { children: ReactNode }) {
    const router = useRouter();

    const routePath = extractActualRoutePath(router.asPath, router.pathname)
    logger.debug(`authGuard - routePath: ${routePath}, router.pathname: ${router.pathname}`)

    const {
        buildAuthorizeUrl,
        logout: logoutAuth0
    } = useAuth0();

    useEffect(() => {
        if (!isLoggedIn()) {
            // when token expires session might still exists, try to refresh tokens first
            refreshTokens(async (reason) => {
                savePostLoginRoute(routePath)
                // logging out
                const logoutRedirectUrl = await buildAuthorizeUrl()
                await logout(false, logoutAuth0, logoutRedirectUrl)
            }, () => {
                // refreshed tokens and stay logged in
                if (!getCustomerId() && routePath != "/signup") {
                    router.push("/signup");
                } else if (routePath === "/") {
                    router.push("/home");
                } else { 
                    router.push(routePath);
                }
            })
        } else {
            // stay logged in
            if (!getCustomerId() && routePath != "/signup") {
                router.push("/signup");
            } else if (routePath === "/") {
                router.push("/home");
            }
        }
    }, [isLoggedIn(), routePath])

    if (isLoggedIn()) {
        return <>{children}</>
    }

    return null
}