export class Customer {
    id: string
    isVerified: boolean
    email: string
    // extend as we need

    constructor(data: any) {

        const customerData = data?.customer

        const kycStatus = customerData?.kycStatus == "VERIFIED"

        this.id = customerData?.id
        this.isVerified = kycStatus
        this.email = customerData?.email
    }
}