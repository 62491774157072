import jwtDecode from "jwt-decode";
import { clearApolloCache } from "../../graphql/client/client";
import { AuthTokens } from "../../models/auth/authTokens";
import { flush, trackLogout } from "../../services/analyticsService";
import { loginStore, store } from "../../store";
import { accountLogin, accountSignout } from "../../store/actions/account";
import { postLoginRedirect } from "../../store/actions/login";
import { LogoutOptions } from '@auth0/auth0-react';
import * as auth0 from "auth0-js"
import { getGlobalWindow } from "../window";
import { logger } from "../logger";


export const SESSION_STORAGE_KEY_LOGIN_STATE = "login_state"
export const SESSION_STORAGE_KEY_POST_LOGIN_ROUTE = "post_login_route"
export const AUTH0_ERROR_LOGIN_REQUIRED = "login_required"

export enum Auth0ErrorReason {
    LOGIN_REQUIRED = "LOGIN_REQUIRED",
    OTHER = "OTHER"
}

var auth0WebAuth: auth0.WebAuth

export function getAuth0WebAuth() {
    if (!auth0WebAuth) {
        throw new Error("auth0WebAuth need to be initialised when window object is ready.")
    }

    return auth0WebAuth
}

export function initAuth0WebAuth() {
    const redirectUriFromQueryParams = parseAuthLocationQueryParams().redirectUri
    const actualRedirectUri = process.env.NEXT_PUBLIC_ENV != 'production' && !!redirectUriFromQueryParams
        ? redirectUriFromQueryParams : process.env.AUTH0_AUTH_CALLBACK_URL
    auth0WebAuth = new auth0.WebAuth({
        domain: process.env.AUTH0_DOMAIN,
        clientID: process.env.AUTH0_CLIENT_ID,
        redirectUri: actualRedirectUri,
        audience: process.env.AUTH0_AUDIENCE,
        scope: 'openid profile email address phone offline_access',
        responseType: 'token id_token'
    })
    return true;
}

export function getAccessToken() {
    return store.getState().account.accessToken
}

export function getCustomerId() {
    return store.getState().account.customerId
}

export function getIdToken() {
    return store.getState().account.idToken
}

export function getIdTokenPayload() {
    return store.getState().account.idTokenPayload
}

/**
 * Extract tokens from current url location hash
 * 
 * TODO, not used atm, need to figure how to let s3 redirect rules carry over query params and hash fragments first
 */
export async function extractTokens(cb: (boolean) => void) {
    const parseResult = await new Promise((resolve, reject) => {
        if (getGlobalWindow().location.hash.includes("#") && getGlobalWindow().location.hash.includes("access_token")) {
            getAuth0WebAuth().parseHash({
                hash: getGlobalWindow().location.hash,
                state: parseLoginCallbackLocationHashParams().loginState // TODO, this is a hack to bypass the state check in auth0 SDK, for environment that has auth0 custom domain enabled, we shouldn't do this hack
            }, (error, authResult) => {
                if (error) {
                    logger.error(error)
                    return reject(false)
                }

                setAuthTokens({
                    accessToken: authResult.accessToken,
                    idToken: authResult.idToken,
                    idTokenPayload: authResult.idTokenPayload,
                    expiresIn: authResult.expiresIn
                })

                resolve(true)
            })
        } else {
            reject(false)
        }
    })
    cb(parseResult)
}


export function setAuthTokens(tokens: AuthTokens) {
    store.dispatch(accountLogin(tokens))
}

export async function refreshTokens(onError: (reason?: Auth0ErrorReason) => void = () => { }, onSuccess: () => void = () => { }) {
    return new Promise((resolve, reject) => {
        getAuth0WebAuth().checkSession({}, (error, authResult) => {
            if (!!error) {
                logger.error(error)

                const errorReason = error.error == 'login_required' ? Auth0ErrorReason.LOGIN_REQUIRED : Auth0ErrorReason.OTHER
                onError(errorReason)

                return reject(false)
            }

            setAuthTokens(authResult)

            onSuccess()

            resolve(true)
        })
    })
}

export async function logout(
    shouldTrack: Boolean,
    logoutAuth0: (options?: LogoutOptions) => void,
    logoutRedirectUri: string = `${getGlobalWindow().location.origin}`
) {
    if (shouldTrack) trackLogout()
    store.dispatch(accountSignout())
    await flush()
    localStorage.removeItem("state")
    clearApolloCache()
    sessionStorage.clear()
    await logoutAuth0({ returnTo: logoutRedirectUri })
}

/**
 * Extract auth result fields including access_token, id_token, etc from hash fragment of the login callback location
 * 
 * @returns key-value pairs of auth result fields
 */
export function parseLoginCallbackLocationHashParams() {
    const str = getGlobalWindow().location.hash.replace("#", "")
    let pieces = str.split("&"), data = {}, i, parts
    // process each query pair
    for (i = 0; i < pieces.length; i++) {
        parts = pieces[i].split("=");
        if (parts.length < 2) {
            parts.push("")
        }
        data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1])
    }

    const state = data['state']

    return new AuthResultHashParams(state)
}

export function parseAuthLocationQueryParams() {
    const urlSearchParams = new URLSearchParams(getGlobalWindow().location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const state = params['state']

    const redirectUri = params['redirect_uri']

    return new AuthLocationQueryParams(state, redirectUri)
}

/**
 * auth0 login callback page hash parameters
 * 
 * - Only store state at the moment, might need more fields in the future
 * - we need to the state to bypass the state check in auth0 SDK, but we don't need this on production coz we will enable custom domain
 */
class AuthResultHashParams {
    constructor(readonly loginState: string) { }
}

/**
 * auth0 login page query parameters
 * 
 * - Only store state at the moment, might need more fields like nonce in the future
 */
class AuthLocationQueryParams {
    constructor(readonly loginState: string, readonly redirectUri: string) { }
}

/**
 * Check if has unexpired token and customer id
 */
export function isLoggedIn(): boolean {
    // checks if access token is expired
    const token = getAccessToken()
    const hasToken = !!token
    const isTokenValid = !isTokenExpired(token)
    return hasToken && isTokenValid
}

function isTokenExpired(accessToken: string) {
    try {
        const decodedAccessToken = jwtDecode(accessToken)
        const tokenIsExpired = Date.now() >= decodedAccessToken["exp"] * 1000;
        return tokenIsExpired
    } catch {
        return true
    }
}

/**
 * Save the route path that we want to redirect to after login successfully
 */
export function savePostLoginRoute(routePath: string) {
    loginStore.dispatch(postLoginRedirect(routePath))
}

export function clearPostLoginRoute() {
    loginStore.dispatch(postLoginRedirect())
}


/**
 * Consume post login route after login suceeded and removed it from storage
 */
export function consumePostLoginRouteIfExist(): string | undefined {
    const routePath = loginStore.getState().login.postLoginRedirectRoute

    if (!!routePath) {
        clearPostLoginRoute()
    }

    return routePath
}
