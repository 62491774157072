import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { createWrapper } from "next-redux-wrapper"
import rootReducer, { analyticsRootReducer, loginRootReducer } from "./reducers"
import { loadState, saveState } from "./localStorage/localStorage"


// middleware
const middleware = [thunk]

// creating store
const persistedState = loadState()

export const store = createStore(
	rootReducer,
	persistedState,
	composeWithDevTools(applyMiddleware(...middleware))
)

// assigning store to next wrapper
const makeStore = () => store
export const wrapper = createWrapper(makeStore)

store.subscribe(() => {
	saveState(store.getState())
})


// creating timer store
const persistedAnalyticsState = loadState('analytics')

export const analyticsStore = createStore(
	analyticsRootReducer,
	persistedAnalyticsState,
	composeWithDevTools(applyMiddleware(...middleware))
)

// assigning timer store to next wrapper
const makeTimerStore = () => analyticsStore
export const timerWrapper = createWrapper(makeTimerStore)

analyticsStore.subscribe(() => {
	saveState(analyticsStore.getState(), 'analytics')
})


// creating login store
const persistedLoginState = loadState('login')

export const loginStore = createStore(
	loginRootReducer,
	persistedLoginState,
	composeWithDevTools(applyMiddleware(...middleware))
)

// assigning store to next wrapper
const makeLoginStore = () => store
export const loginStoreWrapper = createWrapper(makeLoginStore)

loginStore.subscribe(() => {
	saveState(loginStore.getState(), 'login')
})