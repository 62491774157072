import React, { useState, useEffect, useMemo } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider} from "@mui/material";
import { ThemeProvider as ScThemeProvider } from "styled-components";
import { CssBaseline } from '@mui/material';

import { dashboardTheme } from "./dashboardTheme";
import { KEY_FOR_THEME_MODE } from "../../util/constants";

export const DashboardThemeContext = React.createContext({
  toggleColorMode: () => {},
});

export const DashboardThemeProvider = ({ children }) => {
  const [mode, setMode] = useState('dark');

  useEffect(() => {
    setMode(window.localStorage.getItem(KEY_FOR_THEME_MODE) || mode)
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light"
          window.localStorage.setItem(KEY_FOR_THEME_MODE, newMode);
          return newMode
        });
      },
    }),
    []
  );

  const theme = useMemo(() => dashboardTheme(mode), [mode]);

  return (
    <DashboardThemeContext.Provider value={colorMode}>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScThemeProvider theme={theme}>
            {children}
          </ScThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </DashboardThemeContext.Provider>
  );
};