import { useQuery } from "@apollo/client";
import { ReactNode, useEffect, useState } from "react";
import { GET_CUSTOMER } from "../../graphql/customer";
import { Customer } from "../../models/customer/customer";
import { store } from "../../store";
import { setCustomerDetails } from "../../store/actions/customer";
import { sleep } from "../../util/utils";
import { GenericError } from "./errorBoundary";
import { useLoading } from "./globalLoaderBoundary";

/**
 * guard for ensuring we have all the glbal customer details set into cache
 * @param param0 
 */
export function CustomerGuard({ children }: { children: ReactNode }) {
    const [hasSetData, setHasData] = useState(false)
    const { loading, setLoading } = useLoading();
    /**
     * fetch customer details
     * - save important customer details 
     */
    const { data, loading: customerLoading, error } = useQuery(GET_CUSTOMER)
    
    useEffect(() => {
        const saveDetails = async () => {
            const customer = new Customer(data)
            store.dispatch(setCustomerDetails(customer))
            setHasData(true)
        }
        if (data) { saveDetails() }
    }, [data])

    if (error) {
        throw GenericError
    }
    
    useEffect(() => {
        const setLoadingUI = async () => {
            if (customerLoading == false) {
                // sleep for UI purposes
                await sleep(300)
            }
            setLoading(customerLoading)
        }
  
        setLoadingUI()

    }, [customerLoading, setLoading])


    // TODO: global loading / error state handling here
    if (hasSetData) {
        return <>{children}</>
    } else {
        return <></>
    }
}