import * as actionTypes from '../actions/actionTypes'

const reducer = (state = { ipAddress: null }, action) => {
    switch (action.type) {
        case actionTypes.ipAddressObtainedAction:
            return {
                ipAddress: action.payload
            }
        default: return state
    }
}

export default reducer
