
import { Box, useTheme } from "@mui/material";
import { createContext, ReactNode, useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { PrimaryCircularProgress } from "../loader/primaryLoader";

const LoadingContext = createContext({
    loading: false,
    setLoading: null,
  });

/**
 * global loading provider
 */
export function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const value = { loading, setLoading };
    return (
        <LoadingContext.Provider value={value}>
            <LoadingBoundary>{children}</LoadingBoundary>
        </LoadingContext.Provider>
    );
}
  
function LoadingBoundary({ children }: { children: ReactNode }) {
    const { breakpoints } = useTheme();
    const { loading } = useLoading();
    return (
        <>
        {
            loading && (
                <Box sx={{
                    width:'100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'button.background.primary',
                    minHeight: '100vh',
                    paddingBottom: '90px',
                    [breakpoints.down('md')]:{
                        height: isMobile ? `calc(${window.innerHeight}px - 66px)` : 'calc(100vh - 66px)',
                    }
                }}>
                    <PrimaryCircularProgress />
                </Box>)
        }
            <Box style={{ display: loading ? 'none' : 'inherit' }}>{children}</Box>
        </>
    )
}

/**
 * hook for app to use
 */
 export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
      throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}