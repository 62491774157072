import * as actionTypes from '../actions/actionTypes'

const reducer = (state = { email: null, isVerified: null, isInWaitlist: null }, action) => {
    switch (action.type) {
        case actionTypes.setCustomerDetailsAction:
            return {
                email: action.payload.email,
                isVerified: action.payload.isVerified,
                isInWaitlist: state.isInWaitlist
            }
        case actionTypes.setCustomerWaitlistStateAction:
            return {
                email: state.email,
                isVerified: state.isVerified,
                isInWaitlist: action.payload
            }
        default: return state
    }
}

export default reducer
