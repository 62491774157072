import * as actionTypes from '../actions/actionTypes'
var decode = require('jwt-claims')

const customerIdClaimKey = process.env.CUSTOMER_ID_CLAIM_KEY

const reducer = (state = { customerId: null, accessToken: null, idToken: null, idTokenPayload: null }, action) => {
    switch (action.type) {
        case actionTypes.accountLoginAction:
            return {
                customerId: decode(action.payload.accessToken)[customerIdClaimKey],
                accessToken: action.payload.accessToken,
                idToken: action.payload.idToken,
                idTokenPayload: action.payload.idTokenPayload
            }
        case actionTypes.accountSignoutAction:
            return { customerId: null, accessToken: null, idToken: null, idTokenPayload: null }
        default: return state
    }
}

export default reducer
