import { Event } from "../../services/analyticsService";
import { addToAnalyticsQueueAction, clearAnalyticsQueueAction, incrementFlushQueueTimerAction, setFingerPrintAction, setReferralCodeAction } from "./actionTypes"


export const addToQueue = (event: Event) => ({ type: addToAnalyticsQueueAction, payload: event })

export const clearQueue = () => ({ type: clearAnalyticsQueueAction })

export const updateSecondsBeforeFlush = () => ({ type: incrementFlushQueueTimerAction })

export const setReferralCode = (code: string) => ({ type: setReferralCodeAction, payload: code })

export const setFingerPrint = (fingerprint: string) => ({ type: setFingerPrintAction, payload: fingerprint })
