import { Logger } from "ts-log"

const isTestEnv = process.env.NEXT_PUBLIC_ENV != "production"

class TiiikLogger implements Logger {
    private outputLogger: Logger = console
    trace(message?: any, ...optionalParams: any[]): void {
        if (isTestEnv) {
            this.outputLogger.trace(message, ...optionalParams)
        }
    }
    debug(message?: any, ...optionalParams: any[]): void {
        if (isTestEnv) {
            this.outputLogger.debug(message, ...optionalParams)
        }
    }
    info(message?: any, ...optionalParams: any[]): void {
        this.outputLogger.info(message, ...optionalParams)
    }
    warn(message?: any, ...optionalParams: any[]): void {
        this.outputLogger.warn(message, ...optionalParams)
    }
    error(message?: any, ...optionalParams: any[]): void {
        this.outputLogger.error(message, ...optionalParams)
    }
}

export const logger = new TiiikLogger()