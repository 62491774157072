export const accountLoginAction = "ACCOUNT_LOGIN"
export const accountSignoutAction = "ACCOUNT_SIGNOUT"
export const postLoginRedirectAction = "POST_LOGIN_REDIRECT"

export const ipAddressObtainedAction = "IP_ADDRESS_OBTAINED"
export const setFingerPrintAction = "SET_FINGERPRINT_ACTION"

export const setCustomerDetailsAction = "SET_CUSTOMER_DETAILS_ACTION"
export const setCustomerWaitlistStateAction = "SET_CUSTOMER_WAITLIST_STATE_ACTION"

export const addToAnalyticsQueueAction = "ADD_TO_ANALYTICS_QUEUE_ACTION"
export const clearAnalyticsQueueAction = "CLEAR_ANALYTICS_QUEUE_ACTION"
export const incrementFlushQueueTimerAction = "INCREMENT_FLUSH_QUEUE_TIMER_ACTION"
export const setReferralCodeAction = "SET_REFERRAL_CODE_ACTION"

export const setUnreadMessagesCountAction = "SET_UNREAD_MESSAGES_COUNT_ACTION"

export const incrementOriginationSendAction = "INCREMENT_ORIGINATION_SEND_ACTION"
export const clearOriginationSendAction = "CLEAR_ORIGINATION_SEND_ACTION"