import { createTheme } from "@mui/material/styles";

export const dashboardTheme = (mode: string) => {
  const currentTheme = createTheme(mode === 'light' ? lightTheme : darkTheme);
  return createTheme(currentTheme, {
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            title1: 'h6',
            title2: 'h6',
            body3: 'p',
            body4: 'p',
            logo: 'h1',
            button1: 'p',
            button2: 'p',
            button3: 'p',
            button4: 'p',
          },
        },
      },
    },
    typography: {
      fontFamily: 'DM Sans',
      h1: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '70px',
        lineHeight: '91.14px',
      },
      h2: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '48px',
        lineHeight: '62px',
      },
      h3: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '40px',
        lineHeight: '52px',
      },
      h4: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '42px',
      },
      h5: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '31.25px',
      },
      h6: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '31.25px',
      },
      title1: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '23px',
      },
      title2: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
      },
      body1:{
        color: currentTheme.custom.text.primary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '21px',
      },
      body2: {
        color: currentTheme.custom.text.secondary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
      },
      body3: {
        color: currentTheme.custom.text.secondary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '18px',
      },
      body4: {
        color: currentTheme.custom.text.secondary,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
      },
      logo: {
        color: currentTheme.custom.text.primary,
        fontFamily: 'Monument Extended',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '14px',
        lineHeight: '18px',
        textTransform: 'uppercase'
      },
      logoCaption: {
        color: currentTheme.custom.accent,
        fontFamily: 'Moderat',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '10px',
        lineHeight: '12px',
        textTransform: 'lowercase'
      },
      caption: {
        color: currentTheme.custom.text.caption,
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
      },
      button1: {
        color: currentTheme.custom.button.text.primary,
        textTransform: 'none',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '21px',
      },
      button2: {
        color: currentTheme.custom.button.text.secondary,
        textTransform: 'none',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '21px',
      },
      button3: {
        color: currentTheme.custom.button.text.tertiary,
        textTransform: 'none',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '15px',
        lineHeight: '20px',
      },
      button4: {
        color: currentTheme.custom.button.text.text,
        textTransform: 'none',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '21px',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1440,
        xl: 1920,
      },
    },
    palette: {
      mode: mode,
      ...currentTheme.custom
    }
  });
}

export const lightTheme = {
  custom: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#161623',
    },
    accent: "#524CED",
    surface: {
      primary: 'rgba(214, 214, 223, 0.4)',
      secondary: 'rgba(0, 0, 0, 0.2)',
    },
    background: {
      primary: '#F6F6F8',
      secondary: '#FFFFFF',
      inset: 'linear-gradient(0deg, rgba(25, 27, 29, 0.04), rgba(25, 27, 29, 0.04)), #F5F5F7',
      contrast: '#F6F6F8',
      gradient: 'rgb(82 76 237 / 10%)',
      default: '#FFFFFF',
    },
    contentDivider: {
      appbar: '1px solid #D6D6DF',
      sidebar: '1px solid #0000304d',
      card: '1px solid rgba(214, 214, 223, 0.4)'
    },
    chip: {
      border: {
        primary: 'rgba(214, 214, 223, 0.4)',
        secondary: '#000030',
        inset: 'rgba(214, 214, 223, 0.4)',
      },
      background: {
        primary: '#F0F0F0',
        secondary: '#F5F5F7',
        inset: '#F0F0F0',
      }
    },
    switch: {
      track: {
        bg: '#EBEBF0',
      },
      thumb: {
        bg: '#FAFAFA',
        border: '1px solid #F0F0F0'
      }
    },
    loader: {
      primary: '#F5F5F7',
      secondary: 'linear-gradient(0deg, rgba(224, 220, 244, 0.04), rgba(224, 220, 244, 0.04)), #EBEBF0',
      anim: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent)'
    },
    input: {
      background: '#ECECEE',
      primary: '#9892FF',
      secondary: 'transparent',
      error: '#f1382e',
      hover: '#D6D6DF'
    },
    button: {
      text: {
        primary: '#FFFFFF',
        secondary: '#000030',
        tertiary: '#FFFFFF',
        text: '#524CED',
      },
      background: {
        primary: '#524CED',
        secondary: 'transparent',
        tertiary: '#524CED',
      },
      border: {
        primary: 'none',
        secondary: '1px solid #000030',
        tertiary: 'none',
      }
    },
    text: {
      primary: '#000030',
      secondary: '#333360',
      subtitle: '#00003080',
      caption: '#000030a6',
      tooltip: '#9898AF',
      success: '#66BA00',
      pending: '#FFBC3A',
      failure: '#B8263D',
    },
  }
};

export const darkTheme = {
  custom: {
    primary: {
      main: '#111314'
    },
    secondary: {
      main: '#161623',
    },
    accent: "#9892FF",
    surface: {
      primary: 'rgba(224, 220, 244, 0.2)',
      secondary: 'rgba(224, 220, 244, 0.2)',
    },
    background: {
      primary: 'linear-gradient(0deg, rgba(224, 220, 244, 0.04), rgba(224, 220, 244, 0.04)), #111314',
      secondary: 'linear-gradient(0deg, rgba(224, 220, 244, 0.08), rgba(224, 220, 244, 0.08)), #111314',
      inset: 'linear-gradient(0deg, rgba(224, 220, 244, 0.08), rgba(224, 220, 244, 0.08)), #111314',
      contrast: 'linear-gradient(0deg, #111314, #111314), #111314',
      gradient: 'rgb(82 76 237 / 25%)',
      default: '#111314'
    },
    contentDivider: {
      appbar: '1px solid #4E4E58',
      sidebar: '1px solid #ffffff4d',
      card: '1px solid #53586599'
    },
    chip: {
      border: {
        primary: 'rgba(224, 220, 244, 0.2)',
        secondary: "#FAFAFA",
        inset: 'rgba(224, 220, 244, 0.2)'
      },
      background: {
        primary: '#060709',
        secondary: 'linear-gradient(0deg, rgba(224, 220, 244, 0.04), rgba(224, 220, 244, 0.04)), #111314',
        inset: '#060709',
      },
    },
    switch: {
      track: {
        bg: '#333360',
      },
      thumb: {
        bg: '#000030',
        border: '1px solid #020211'
      }
    },
    loader: {
      primary: '#191B1D',
      secondary: '#111314',
      anim: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.02), transparent)',
    },
    input: {
      background: 'rgb(73, 69, 79)',
      primary: '#9892FF',
      secondary: '#CAC4D0',
      error: '#F2B8B5',
      hover: 'rgba(224, 220, 244, 0.07)'
    },
    button: {
      text: {
        primary: '#000030',
        secondary: '#FAFAFA',
        tertiary: '#141417',
        text: '#9892FF',
      },
      background: {
        primary: '#9892FF',
        secondary: 'transparent',
        tertiary: '#A3A2FF',
      },
      border: {
        primary: 'none',
        secondary: '1px solid #FAFAFA',
        tertiary: 'none',
      }
    },
    text: {
      primary: '#FAFAFA',
      secondary: '#D6D6DF',
      subtitle: '#fafafa80',
      caption: 'rgb(250 250 250 / 65%)',
      tooltip: '#9898AF',
      success: '#66BA00',
      pending: '#FFBC3A',
      failure: '#B8263D',
    },
  }
};

interface CustomOptions {
  primary: {
    main: string,
  },
  secondary: {
    main: string,
  },
  accent: string,
  surface: {
    primary: string,
    secondary: string,
  },
  background: {
    primary: string,
    secondary: string,
    inset: string,
    contrast: string,
    gradient: string,
    default: string,
  },
  contentDivider: {
    appbar: string,
    sidebar: string,
    card: string
  },
  chip: {
    border: {
      primary: string,
      secondary: string,
      inset: string,
    }
    background: {
      primary: string,
      secondary: string,
      inset: string,
    }
  },
  switch: {
    track: {
      bg: string,
    },
    thumb: {
      bg: string,
      border: string,
    }
  },
  loader: {
    primary: string,
    secondary: string,
    anim: string,
  },
  input: {
    background: string,
    primary: string,
    secondary: string,
    error: string,
    hover: string,
  },
  button: {
    text: {
      primary: string,
      secondary: string,
      tertiary: string,
      text: string,
    },
    background: {
      primary: string,
      secondary: string,
      tertiary: string,
    },
    border: {
      primary: string,
      secondary: string,
      tertiary: string,
    }
  },
  text: {
    primary: string,
    secondary: string,
    subtitle: string,
    caption: string,
    tooltip: string,
    success: string,
    pending: string,
    failure: string,
  },
}

declare module '@mui/material/styles' {
  interface Theme {
    custom: CustomOptions,
  }
  interface ThemeOptions {
    custom: CustomOptions,
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true,
    title2: true,
    body3: true,
    body4: true,
    logo: true,
    logoCaption: true,
    button1: true,
    button2: true,
    button3: true,
    button4: true,
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette extends CustomOptions {

  }
  interface PaletteOptions extends CustomOptions {
    
  }
}