import * as actionTypes from '../actions/actionTypes'
import { v4 as uuidv4 } from 'uuid'

const reducer = (state = { queue: [], flushQueueTimer: 0, deviceId: null, referralCode: null, fingerprint: null }, action) => {
    switch (action.type) {
        case actionTypes.addToAnalyticsQueueAction:
            const event = action.payload
            var currentQueue = state.queue

            const deviceId = state.deviceId ?? uuidv4()
            if (event.deviceId == null) event.deviceId = deviceId
            
            currentQueue.push(event)

            return {
                queue: currentQueue,
                deviceId: deviceId,
                referralCode: state.referralCode,
                flushQueueTimer: state.flushQueueTimer,
                fingerprint: state.fingerprint
            }
        case actionTypes.clearAnalyticsQueueAction:
            return {
                queue: [],
                deviceId: state.deviceId ?? uuidv4(),
                referralCode: state.referralCode,
                flushQueueTimer: state.flushQueueTimer,
                fingerprint: state.fingerprint
            }
        case actionTypes.incrementFlushQueueTimerAction:
            var newTime = state.flushQueueTimer + 1
            if (newTime > 30) newTime = 0
            return {
                queue: state.queue,
                deviceId: state.deviceId ?? uuidv4(),
                referralCode: state.referralCode,
                flushQueueTimer: newTime,
                fingerprint: state.fingerprint
            }
        case actionTypes.setReferralCodeAction:
            return {
                queue: state.queue,
                deviceId: state.deviceId ?? uuidv4(),
                referralCode: action.payload,
                flushQueueTimer: state.flushQueueTimer,
                fingerprint: state.fingerprint
            }
        case actionTypes.setFingerPrintAction:
            return {
                queue: state.queue,
                deviceId: state.deviceId ?? uuidv4(),
                referralCode: state.referralCode,
                flushQueueTimer: state.flushQueueTimer,
                fingerprint: action.payload
            }
        default: return state
    }
}

export default reducer
