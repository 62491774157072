import { setUnreadMessagesCountAction } from "../actions/actionTypes"

const reducer = (state = { unreadMessageCount: 0 }, action) => {
    switch (action.type) {
        case setUnreadMessagesCountAction:
            return {
                unreadMessageCount: action.payload
            }
        default: return state
    }
}

export default reducer
