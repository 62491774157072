import { logger } from '../modules/logger'
import { analyticsStore, store } from '../store'
import { addToQueue, clearQueue } from '../store/actions/analytics'
import { HttpClient } from './httpClient'


const host = 'app.tiiik.money'

const platformName = 'Web'

const queueThreshold = 15

const httpClient = new HttpClient(process.env.ANALYTICS_API_URL)

export async function trackLogout() {
    try {
        const customerId = store.getState().account.customerId as string
        const event = {
            eventType: EventType.track,
            eventName: 'logout',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            eventProperties: null,
            timestamp: new Date().toISOString()
        } as Event

        await track(event, false)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackOriginationStep(step: OriginationStep, extraProps?: { [key: string]: any }) {
    try {
        const event = {
            eventType: EventType.track,
            eventName: 'origination_step',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: null,
            sessionId: null,
            timestamp: new Date().toISOString(),
            eventProperties: {
                step: step,
                ...extraProps
            }
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackLogin() {
    try {
        const customerId = store.getState().account.customerId as string
        
        const event = {
            eventType: EventType.track,
            eventName: 'login',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            eventProperties: null,
            timestamp: new Date().toISOString()
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackProfileInfoSubmitted() {
    try {
        const customerId = store.getState().account.customerId as string

        const event = {
            eventType: EventType.track,
            eventName: 'profile_info_submitted',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            eventProperties: null,
            timestamp: new Date().toISOString()
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackAcceptedTermsAndConditions() {
    try {
        const customerId = store.getState().account.customerId as string

        const event = {
            eventType: EventType.track,
            eventName: 'terms_conditions_accepted',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            eventProperties: null,
            timestamp: new Date().toISOString()
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackProfileCompleted() {
    try {
        const customerId = store.getState().account.customerId as string

        const event = {
            eventType: EventType.track,
            eventName: 'profile_completed',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            eventProperties: null,
            timestamp: new Date().toISOString()
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackVerificationCompleted() {
    try {
        const customerId = store.getState().account.customerId as string

        const event = {
            eventType: EventType.track,
            eventName: 'verification_completed',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            eventProperties: null,
            timestamp: new Date().toISOString()
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackError(errorName: string, page: Page, reason?: string, extraProps?: { [key: string]: any }) {
    try {
        const customerId = store.getState().account.customerId as string
        
        const event = {
            eventType: EventType.track,
            eventName: 'error',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            timestamp: new Date().toISOString(),
            eventProperties: {
                page: page,
                name: errorName,
                reason: reason,
                ...extraProps
            }
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackInteraction(type: InteractionType, page: Page, reference?: string, extraProps?: { [key: string]: any }) {
    try {
        const customerId = store.getState().account.customerId as string

        const event = {
            eventType: EventType.track,
            eventName: 'interaction',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            timestamp: new Date().toISOString(),
            eventProperties: {
                page: page,
                type: type,
                reference: reference,
                ...extraProps
            }
        } as Event

        await track(event, false)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function trackReferralCodeRedeemed(campaignId: number) {
    try {
        const customerId = store.getState().account.customerId as string

        const event = {
            eventType: EventType.track,
            eventName: 'referral_code_redeemed',
            deviceId: analyticsStore.getState().analytics.fingerprint,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            timestamp: new Date().toISOString(),
            eventProperties: {
                page: Page.redeemReferralCode,
                campaignId: campaignId
            }
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to track event. Error ${JSON.stringify(error)}`)
    }
}

export async function identifyUser(userProperties: { [key: string]: any }) {
    try {
        const customerId = store.getState().account.customerId as string

        const event = {
            eventType: EventType.identify,
            eventName: null,
            deviceId: null,
            platform: platformName,
            customerId: customerId,
            sessionId: null,
            timestamp: new Date().toISOString(),
            userProperties: {
                ...userProperties
            }
        } as Event

        await track(event, true)
    } catch (error) {
        logger.error(`Failed to identify user. Error ${JSON.stringify(error)}`)
    }
}

async function track(event: Event, shouldFlush: Boolean) {
    analyticsStore.dispatch(addToQueue(event))
    if ((analyticsStore.getState().analytics.queue.length >= queueThreshold) || shouldFlush) await flush()
}

export async function flush() {
    if (analyticsStore.getState().analytics.queue.length == 0) return

    try {
        const request = { host: host, events: analyticsStore.getState().analytics.queue } as TrackEventsRequest
        await httpClient.post('/track', { 'x-tiiik-api-key': process.env.ANALYTICS_API_KEY }, request)
        analyticsStore.dispatch(clearQueue())
    } catch (error) {
        logger.error(`Failed to track events. Error ${JSON.stringify(error)}`)
        if (error?.status == 400) {
            analyticsStore.dispatch(clearQueue())
        }
    }
}

export enum EventType {
    track = 'track',
    identify = 'identify'
}

export enum ErrorReason {
    invalidInput = 'invalid_input',
    serverError = 'server_error',
    tooManyAttempts = 'too_many_attempts'
}

export type TrackEventsRequest = {

    host: string

    events: Event[]

}

export type Event = {

    eventType: EventType

    eventName?: string

    deviceId?: string

    platform: string

    customerId?: string

    sessionId?: string

    timestamp?: string

    eventProperties?: { [key: string]: any }

}

export enum InteractionType {
    view = 'view',
    click = 'click',
    pageView = 'page_view'
}

export enum Page {
    login = 'login',
    signup = 'signup',
    home = 'home',
    activity = 'activity',
    more = 'more',
    settings = 'settings',
    deposit = 'deposit',
    withdraw = 'withdraw',
    onboarding = 'onboarding',
    waitlist = 'waitlist',
    statement = 'statement',
    termsAndConditions = 'termsAndConditions',
    risksAndDisclosures = 'risksAndDisclosures',
    error = 'error',
    verifyEmail = 'verify_email',
    redeemReferralCode = 'redeem_referral_code',
    inviteAFriend = 'invite_a_friend',
    shareReferralCode = 'share_referral_code',
    giveaway = 'giveaway',
    completeProfile = 'complete_profile'
}

export enum OriginationStep {
    sendCodeToMobile = 'send_code_to_mobile',
    verifyCodeFromMobile = 'verify_code_from_mobile',
    addEmailAddress = 'add_email_address'
}
