import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
query GetCustomer {
  customer {
    id
    kycRef
    kycStatus
    name
    email
    phone
    customerType
    dateCreated
    externalRef
    address {
      formattedAddress
    }
  }
}
`