// @ts-nocheck
import { ApolloProvider } from '@apollo/client'
import { Auth0Provider } from "@auth0/auth0-react";
import '../styles/globals.scss'
import { apolloClient } from '../graphql/client/client'
import { DashboardThemeProvider } from '../styles/themes/dashboardThemeProvider';
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import Head from 'next/head'
import { AuthGuard } from '../components/guard/authGuard';
import { useRouter } from 'next/router';
import { CustomerGuard } from '../components/guard/customerGuard';
import ErrorBoundary from '../components/guard/errorBoundary';
import { store, analyticsStore } from '../store';
import { setFingerPrint, setReferralCode, updateSecondsBeforeFlush } from '../store/actions/analytics';
import { flush } from '../services/analyticsService';
import { LoadingProvider } from '../components/guard/globalLoaderBoundary';
import { IntercomProvider } from 'react-use-intercom';
import { setUnreadMessageCount } from '../store/actions/support';
import { getCustomerId, initAuth0WebAuth, isLoggedIn } from '../modules';
import { NextSeo } from 'next-seo';
import NextNProgress from 'nextjs-progressbar';
import { extractActualRoutePath } from '../util/utils';
import { logger } from '../modules/logger';
import { withLDProvider } from "launchdarkly-react-client-sdk";
import FingerprintJS from '@fingerprintjs/fingerprintjs';


type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
	requireAuth?: boolean
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

function SafeHydrate({ children }) {
	return (
		<div suppressHydrationWarning>
			{typeof window === 'undefined' ? null : children}
		</div>
	)
}

function App({ Component, pageProps }: AppPropsWithLayout) {
	const getLayout = Component.getLayout ?? ((page) => page);
	const router = useRouter();
	const [isReady, setIsReady] = useState(false);
	const path = extractActualRoutePath(router.asPath)
	if (path) {
		logger.debug(`replacing path: ${router.asPath} -> ${path}`)
		router.replace(path);
	}

	useEffect(() => {
		obtainFingerPrint()
		checkForReferralCodeInUrl()

		setIsReady(initAuth0WebAuth())
		const interval = setInterval(() => {
			const currentTime = analyticsStore.getState().analytics.flushQueueTimer
			analyticsStore.dispatch(updateSecondsBeforeFlush())
			if (currentTime % 30 == 0) flush()
		}, 1000)

		return () => clearInterval(interval)
	}, []);

	const checkForReferralCodeInUrl = () => {
		const referralCode = getUrlParameterByName('c')
		if (referralCode != null) store.dispatch(setReferralCode(referralCode))
	}

	const getUrlParameterByName = (name): string | null => {
		var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	}

	const obtainFingerPrint = async () => {
		if (analyticsStore.getState().analytics.fingerprint != null && analyticsStore.getState().analytics.fingerprint != undefined) return
		
		try {
			const fp = await FingerprintJS.load()
			const { visitorId } = await fp.get()
			analyticsStore.dispatch(setFingerPrint(visitorId))
		} catch (e) {
			console.log(`Failed to obtain fingerprint ${e}`)
		}
	}

	const renderApp = () => {
		return !isReady ? '' : <Auth0Provider
			domain={process.env.AUTH0_DOMAIN}
			clientId={process.env.AUTH0_CLIENT_ID}
			redirectUri={process.env.AUTH0_AUTH_CALLBACK_URL}
		>
			<ApolloProvider client={apolloClient}>
				<IntercomProvider
					appId={process.env.INTERCOM_APP_ID}
					autoBoot={true}
					shouldInitialize={isLoggedIn()}
					onUnreadCountChange={(count: number) => store.dispatch(setUnreadMessageCount(count))}
					autoBootProps={{
						hideDefaultLauncher: true,
					}}
				>
					<SafeHydrate>
						<DashboardThemeProvider>
							<NextSeo
								title="Stables - Money fit for the future"
								description="One place to spend, send and earn stablecoins."
								canonical={`${process.env.APP_WEB_ASSETS_PREFIX}/`}
								openGraph={{
								type: 'website',
								url: `${process.env.APP_WEB_ASSETS_PREFIX}/`,
								title: 'Stables - Money fit for the future',
								description: 'One place to spend, send and earn stablecoins.',
								images: [
									{
										url: `${process.env.APP_WEB_ASSETS_PREFIX}/static/images/large-banner.png`,
										width: 1200,
										height: 630,
										alt: 'Stables banner',
										type: 'image/png',
									},
								],
								site_name: 'Stables',
								}}
								twitter={{
								handle: '@tiiik_money',
								site: '@tiiik_money',
								cardType: 'summary_large_image',
								}}
							/>
							<Head>
								<title>Stables</title>
								<meta name="description" content="Stables" />
								<meta name="apple-mobile-web-app-capable" content="yes" />
								<link rel="icon" href={`${process.env.APP_WEB_ASSETS_PREFIX}/static/favicon.ico`} />
								<link rel="apple-touch-icon-precomposed" href={`${process.env.APP_WEB_ASSETS_PREFIX}/static/apple-touch-icon.png`} />
							</Head>
							<ErrorBoundary>
								<NextNProgress color="#9892FF" options={{ showSpinner: false }}/>
								{Component.requireAuth ? (
									<AuthGuard>
										{getLayout(
											<ErrorBoundary>
												<LoadingProvider>
													{ !!getCustomerId() ?
														<CustomerGuard>
															<Component {...pageProps} />
														</CustomerGuard>
														: <Component {...pageProps} />
													}
												</LoadingProvider>
											</ErrorBoundary>
										)}
									</AuthGuard>)
									: (getLayout(<Component {...pageProps} />))}
							</ErrorBoundary>
						</DashboardThemeProvider>
					</SafeHydrate>
				</IntercomProvider>

			</ApolloProvider>
		</Auth0Provider>
	}
	return renderApp()
}

export default withLDProvider({
	clientSideID: process.env.LAUNCHDARKLY_CLIENT_ID,
	user: { key: 'no-user-id' }
})(App)
