import { combineReducers } from 'redux'
import accountReducer from './account'
import customerReducer from './customer'
import ipAddressReducer from './ipAddress'
import analyticsReducer from './analytics'
import loginReducer from './login'
import supportReducer from './support'
import originationReducer from './origination'


const rootReducer = combineReducers({
    account: accountReducer,
    ipAddress: ipAddressReducer,
    customer: customerReducer,
    support: supportReducer,
    origination: originationReducer
})

export const analyticsRootReducer = combineReducers({
    analytics: analyticsReducer
})

export const loginRootReducer = combineReducers({
    login: loginReducer
})

export default rootReducer
