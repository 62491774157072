import axios, { AxiosInstance } from 'axios'


export const badRequestStatusCode = 400

export class HttpClient {

    readonly baseURL

    private readonly axiosInstance: AxiosInstance

    constructor(baseURL: string) {
        this.baseURL = baseURL
        this.axiosInstance = axios.create({ baseURL: this.baseURL })
        this.initializeRequestInterceptor()
        this.initializeResponseInterceptor()
    }

    private initializeResponseInterceptor() {
        this.axiosInstance.interceptors.response.use(response => {
            if (response.status == 204) return null
            const { data } = response
            return data
        }, error => {
            const { response } = error
            return Promise.reject(response)
        })
    }

    private initializeRequestInterceptor() {
        this.axiosInstance.interceptors.request.use(request => {
            return request
        }, error => {
            return Promise.reject(error)
        })
    }

    public get<T>(url: string, headers?: { [key: string]: any }): Promise<T> {
        const requestURL = `${this.baseURL}${url}`
        return this.axiosInstance.get(requestURL, { headers: headers })
    }

    public post<T>(url: string, headers?: { [key: string]: any }, data?: any): Promise<T> {
        const requestURL = `${this.baseURL}${url}`
        return this.axiosInstance.post(requestURL, data, { headers: headers })
    }

    public put<T>(url: string, headers?: { [key: string]: any }, data?: any): Promise<T> {
        const requestURL = `${this.baseURL}${url}`
        return this.axiosInstance.put(requestURL, data, { headers: headers })
    }

    public delete<T>(url: string): Promise<T> {
        const requestURL = `${this.baseURL}${url}`
        return this.axiosInstance.delete(requestURL)
    }

}
