import moment from "moment"
import { ReactNode } from "react";
import { createHmac } from "crypto" 
import { logger } from "../modules/logger";
import { getCountry } from "country-currency-map"
import { parsePhoneNumber } from "react-phone-number-input";
import { COUNTRY_CURRENCY_OVERRIDES, ORIGINATION_PHONE_EXTENSIONS } from "./constants";

/**
 * Currency
*/

export enum Currency {
    AUD = "AUD",
    USD = "USD"
}

export function toDollarString(
    amount: number,
    currency: Currency | undefined = undefined,
    formatterOptions: Intl.NumberFormatOptions | undefined = {minimumFractionDigits:2},
) {

    try {
        let currencyPrefix = ""
        switch(currency) {
            case Currency.AUD: {
                currencyPrefix = "A"
                break;
            }
            case Currency.USD: {
                currencyPrefix = ""
                break
            }
        }

        const amountString = amount.toLocaleString(undefined, formatterOptions)
        if (amountString == '') {
            return null
        }

        return `${currencyPrefix}$${amountString}`
    } catch(e) {
        return null
    }
}

export function isFloat(value: any) {
   return (!isNaN(value) && value.toString().indexOf('.') != -1 || !isNaN(value))
}

export function isFloatTwoDecimalPlaces(value: any) {
    try {
    const isFloatValue = isFloat(value)

    if (isFloatValue) {
        const afterDecimalPlace = value.toString()?.split(".")[1]
        return afterDecimalPlace?.length <= 2 || afterDecimalPlace == undefined
    } 
    return false
    } catch(e) {
        return false
    }
}

/**
 * Date
*/


export function getTimeOfDay() {
    const today = new Date()
    var hours = new Date().getHours()
    if (hours < 12) {
        return 'morning'
    } else if (hours < 17) {
        return 'afternoon'
    } else {
        return 'evening'
    }
}

export function toReadableDate(date: string, format: string) {
    var obj = new Date(date)
    var res = moment(obj).format(format)
    return res
}

export function sortByDate(array: any[], dateFeild: string): any[] {
    if (array == undefined) return

    const copy = [...array]
    return copy.sort(function (left, right) {
        const result = moment.utc(right[dateFeild]).diff(moment.utc(left[dateFeild]))
        return result
    });
  }


export function randstr(prefix) {
    return Math.random().toString(36).replace('0.',prefix || '');
}

export const createSyntheticEvent = <T extends Element, E extends Event>(event: E): React.SyntheticEvent<T, E> => {
    let isDefaultPrevented = false;
    let isPropagationStopped = false;
    const preventDefault = () => {
      isDefaultPrevented = true;
      event.preventDefault();
    }
    const stopPropagation = () => {
      isPropagationStopped = true;
      event.stopPropagation();
    }
    return {
      nativeEvent: event,
      currentTarget: event.currentTarget as EventTarget & T,
      target: event.target as EventTarget & T,
      bubbles: event.bubbles,
      cancelable: event.cancelable,
      defaultPrevented: event.defaultPrevented,
      eventPhase: event.eventPhase,
      isTrusted: event.isTrusted,
      preventDefault,
      isDefaultPrevented: () => isDefaultPrevented,
      stopPropagation,
      isPropagationStopped: () => isPropagationStopped,
      persist: () => {},
      timeStamp: event.timeStamp,
      type: event.type,
    };
  }
  
/**
 * MISC
*/

export async function copyToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
  
// fixed decimal places without rounding
export function toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)?.[0];
}

export function createOnChangeEvent(value: string) {
    const target = document.createElement('input');
    target.value = value;
    const event = new Event('change', { bubbles: true });
    Object.defineProperty(event, 'target', { writable: false, value: target })
    return createSyntheticEvent(event) as React.ChangeEvent<typeof target>;
}

export function sleep(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

// react-pdf bug workaround for React 17+
export function componentWithChildren<Props>(Component: React.ComponentType<Props>) {
    return Component as React.ComponentType<Props & { children: ReactNode }>;
}

export function hmacDigest(message: string): string {
    try {
        return createHmac('SHA256', process.env.INTERCOM_APP_VERIFICATION_KEY)
        .update(message)
        .digest()
        .toString('hex');
    } catch (e) {
        logger.error(e)
    }
}

/**
 * Extract nextjs route path from an s3 redirect route path
 * 
 * @param s3RoutePath the raw s3 url path
 * @param defaultRoutePath when /#! is not found in the path, provides defaultRoutePath as the actual route path
 */
export function extractActualRoutePath(s3RoutePath: string, defaultRoutePath? : string): string | undefined | null {
    let path = (/#!(\/.*)$/.exec(s3RoutePath) || [])[1];

    if (!path && !!defaultRoutePath) {
        path = defaultRoutePath
    }

    return path
}

export function getCurrencyByPhoneNumber(phoneNumber: string): string | undefined | null {
    const countryCode = parsePhoneNumber(phoneNumber);
    const country = ORIGINATION_PHONE_EXTENSIONS.find(({code}) => {
        return code == `+${countryCode.countryCallingCode}`
    })
    const countryOverride = COUNTRY_CURRENCY_OVERRIDES.find(({name}) => name == country.name);
    if (countryOverride) {
        return countryOverride.currency;
    } else {
        return getCountry(country.name)?.currency;
    }
}

export function formatCryptoAddress(address: string) {
    return `${address.slice(0, 13)}...${address.slice(-16)}`
}

export function formatCryptoAddressForWithdrawal(address: string, isForMobile: boolean = false) {
    if (isForMobile) {
        return `${address.slice(0, 10)}...${address.slice(33)}`
    } else {
        return address
    }
}

export function formatCryptoAddressForActivity(address: string) {
    return `${address.slice(0, 4)}...${address.slice(-4)}`
}