import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    from,
    ApolloLink,
    ServerError,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { getAccessToken, refreshTokens } from "../../modules";
import { RetryLink } from "@apollo/client/link/retry";
import Router from "next/router";
import { logger } from "../../modules/logger";

export const PUBLIC_GRAPHQL_URL = process.env.PUBLIC_GRAPHQL_URL
const CLIENT_GRAPHQL_URL = process.env.CLIENT_GRAPHQL_URL
export const APOLLO_PUBLIC_CLIENT_CONTEXT = { clientName: "public" }

/**********************************************************************************
 * Tiiik Apollo Link structure:
 * 
 * devLink or emptyLink -> errorLoggingLink |-> publicHttpLink
 *                                          |-> retryLink -> authErrorLink -> authLink -> privateHttpLink  
 * 
 * 
 *  refresh -> 2 time retry
 **********************************************************************************/


// define http
const publicHttpLink = new HttpLink({
    uri: PUBLIC_GRAPHQL_URL
})

const privateHttpLink = new HttpLink({
    uri: CLIENT_GRAPHQL_URL
})

/**
 * link is for using the web app with local BFF
 */
const devLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
        const result = {
            headers: {
                'tiiik-customer-id': process.env.DEV_CUSTOMER_ID,
                'x-forwarded-for': '1.1.1.1',
                ...headers
            }
        }
        return result
    })
    return forward(operation)
})

const devSplitLink = ApolloLink.split(
    _ => process.env.ENABLE_APOLLO_DEV_LINK == "true",
    devLink,
    null
)

const isAuthError = (networkError: any): networkError is ServerError => {
    return (<ServerError>networkError).statusCode === 401;
}

/**
 * link for attatching auth
 */
const setAuthorisationLink = new ApolloLink((operation, forward) => {
    operation.setContext((prevContext) => {
        const oldHeaders = prevContext.headers
        const result = {
            ...prevContext,
            headers: {
                ...oldHeaders, // the sequence of headers matters, old headers need to be first to avoid overriden the new authorisation
                authorization: `Bearer ${getAccessToken()}`
            }
        }

        return result
    })
    return forward(operation)
})

// add extra logging for errors
const errorLoggingLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
    }

    if (networkError) {
        logger.error(`[Network error]: ${networkError}`)
    }
})

// this will only retry for networkErrors
export const networkErrorRetryLink = new RetryLink({
    attempts: {
        max: 2,
        retryIf: (error, _operation) => {
            // handle auth error
            if (!!error && isAuthError(error)) {
                return refreshTokens()
                    .then(() => {
                        // allow retry for both mutation or query when encounter auth issue, coz no unwanted effect should have happened on server side yet
                        return true
                    })
                    .catch((e) => {
                        Router.push("/logout")
                        return false
                    })
            }

            // allowlist of graphql retriables to avoid unwanted effects, should only be queries (not mutations)
            const retryAllowList = ["GetCustomer", "GetBalanceByCustomer", "GetTransactions", "GetAccountIds"]

            return !!error && retryAllowList.includes(_operation.operationName)
        }
    }
})

/**
 * additive link chain for auth flow
 * -> if found auth error (token expiration/auth0 token update)
 * -> if normal network error then retry for specific queries, and skip handling if auth error
 * -> setup authorisation header, should be reading update-to-date access token in storage
 * -> launch private http request
 */
const authFlowHttpLink = networkErrorRetryLink.concat(setAuthorisationLink).concat(privateHttpLink)

/**
 * this enables us to use two different configurations,
 * for public and private operations
 */
const mainSplitLink = ApolloLink.split(
    // check if public client is specified
    operation => operation.getContext().clientName === APOLLO_PUBLIC_CLIENT_CONTEXT.clientName,
    // use public http link
    publicHttpLink,
    // otherwise use auth link with privateHttpLink
    authFlowHttpLink
)

const cache = new InMemoryCache()
// setup apollo client
export const apolloClient = new ApolloClient({
    link: from([devSplitLink, errorLoggingLink, mainSplitLink]),
    cache: cache,
})

export async function resetApolloCache() {
    await apolloClient.resetStore()
}

/**
 * clear will not tirgger refetch, used for actual logout
 */
export async function clearApolloCache() {
    await apolloClient.clearStore()
}
