import { Button, ButtonBaseProps, SxProps, Typography } from "@mui/material";
import styled from "styled-components";

const StyledButton = styled(Button)(({ theme, variant, disabled }) => ({
    width: 'fit-content',
    whiteSpace: 'nowrap',
    ':not(.MuiButton-text)': {
        height: variant === 'tertiary' ? '40px' : "54px",
        padding: '18px 38px 18px 38px',
        background: theme.palette.button.background[variant],
        borderRadius: '80px',
        border: theme.palette.button.border[variant],
    },
    ':not(:not(.MuiButton-text))': {
        padding: '0px 0px 4px 0px',
        margin: 0,
        color: `${theme.palette.button.text[variant]} !important`,
    },
    '& > p + svg': {
        marginLeft: '8px',
    },
    '& > svg': {
        '& > g': {
            fill: `${theme.palette.button.text[variant]} !important`,
        },
        '& + *': {
            marginLeft: '8px',
        }
    },
    opacity: disabled ? 0.5 : 1,
    color: disabled ? 'unset !important' : 'inherit'
}));

export default function ActionButton({children, label, variant, startAdornment, endAdornment, onClick, textSx, ...rest}: ActionButtonProps) {
    let textVariant;
    switch(variant) {
        case 'text':
            textVariant = 'button4'
            break;
        case 'tertiary':
            textVariant = 'button3'
            break;
        case 'secondary':
            textVariant = 'button2'
            break;
        case 'primary':
        default:
            textVariant = 'button1'
            break;
    }
    return (
        <StyledButton
            onClick={onClick}
            variant={variant}
            {...rest}
        >
            {startAdornment}
            <Typography variant={textVariant} sx={textSx}>{label}</Typography>
            {endAdornment}
        </StyledButton>
    );
}

interface ActionButtonProps extends ButtonBaseProps {
    startAdornment?: React.ReactElement,
    endAdornment?: React.ReactElement,
    label: string,
    variant: 'primary' | 'secondary' | 'tertiary' | 'text'
    textSx?: SxProps
}