
let globalWindow: Window

/**
 * Sharable window object for modules that need to use window object but don't have context of nextjs's readiness
 * 
 * @returns reference to browser window object
 */
export function getGlobalWindow(): Window {
    if (!!window) {
        globalWindow = window
    }

    if (!globalWindow) {
        throw Error("Window object is not ready yet, make sure use window object when component is amounted or in a hook.")
    }

    return globalWindow
}