import * as actionTypes from '../actions/actionTypes'


const reducer = (state = { codesSent: 0, lastSendTime: null }, action) => {
    switch (action.type) {
        case actionTypes.incrementOriginationSendAction:
            const newSendCount = state.codesSent + 1
            return {
                codesSent: newSendCount,
                lastSendTime: new Date().toISOString()
            }
        case actionTypes.clearOriginationSendAction:
            return {
                codesSent: 0,
                lastSendTime: state.lastSendTime
            }
        default: return state
    }
}

export default reducer